@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";

//.sticky-easy {
//    .btn-floating-wrap {
//        .floating-menu.more-plus {
//            bottom:120px !important;
//            @include mobile {
//                bottom:74px !important;
//            }
//        }
//        &.scroll {
//            .floating-menu.more-plus {
//                bottom:190px !important;
//                @include mobile {
//                    bottom:124px !important;
//                }
//            }
//        }
//    }
//    .floating-wrap .floating-menu {
//        .easy-path {
//            .inner {
//                top:auto;
//                bottom:-72px;
//                @include mobile {
//                    bottom:-50px;
//                }
//            }
//            &.scroll {
//                .inner {
//                    bottom:0 !important;
//                }
//            }
//        }
//    }
//    .floating-menu.floating-list {
//        bottom:192px;
//        @include mobile {
//            bottom:124px !important;
//        }
//    }
//    .btn-floating-wrap.scroll {
//        .floating-menu.floating-list {
//            bottom:262px !important;
//            @include mobile {
//                bottom:174px !important;
//            }
//        }
//    }
//}

.story-main {
    .pc-only {
        @include mobile {
            display: none;
        }
    }
    .mo-only {
        @include pc {
            display: none;
        }
    }
    .cont-wrap {
        max-width: 100%;
        padding: 0; 
    }

    .flexbox-wrap {
        .flexbox {
            //position: absolute;
        }
    }
    .story-section {
        &.user_story { 
            display:none; 
            @include mobile{
                padding-bottom:58px;
            }

            .story-title-area {
                display: none;//BTOCSITE-188
            }
        } /* BTOCSITE-787 스토리 - 구독유도 영역 로딩 개선 */
        &.new_story {
            min-height:80vh;
            border-top: 0;
            // margin-top:32px; //BTOCSITE-188 삭제 start
            /* 20210525 추가 */ 
            .inner {
                //padding-top: 60px !important;
                // padding-top: 16px !important; //BTOCSITE-188 삭제
                .title { display:none; }   /* BTOCSITE-787 스토리 - 구독유도 영역 로딩 개선 */
            }
            /* //20210525 추가 */ 

            //BTOCSITE-188 삭제 start
            @include mobile {
                //margin-top: 16px !important;
                // margin-top: 0px !important;
            }
            //BTOCSITE-188 삭제 end
        }

        //BTOCSITE-188
        &.hidden-story-next {
            border-top:16px solid #f4f4f4;

            @include mobile {
                border-width:12px;
                padding-bottom: 80px;
            }

            .inner {
                padding-top:120px;

                @include mobile {
                    padding-top:36px;
                }

                .btn-more {
                    top:174px;
                    @include mobile {
                        top:73px;
                    }
                }
            }
        }
        // border-top: 16px solid $color-bg; //BTOCSITE-188 삭제
        padding-bottom: 120px;
        &:first-child { 
            border-top: 0;
            .inner {
                > .title {
                    //margin-bottom: 20px;
                    word-break: keep-all;
                }
            }
        }        
        
        @include mobile {
            padding-bottom: 58px;
            border-width: 12px;
            &:first-child {
                .inner {
                    > .title {
                        //margin-top: 60px;
                    }
                }
            }
        }
        .inner {
            position: relative;
            max-width: 1460px;
            margin: 0 auto;
            padding: 48px 40px 0;
            @include mobile {
                max-width: 100%;
                padding: 16px 16px 0;
            }
            //BTOCSITE-188 삭제
            // &:first-child {
            //     padding-top: 80px;
            //     @include mobile {
            //         padding-top: 60px;
            //     }
            // }
        }
        .title {
            margin: 0 0 48px;
            font-weight: 700;
            font-size: $font-large-4;
            line-height: 42px;
            text-align: center;
            @include mobile {
                margin: 0 0 28px;
                font-size:20px;
                line-height: 26px;
                margin-bottom: 8px;
            }
        }
        .story-title-area {
            margin: -16px 0 48px;
            padding: 32px 0;
            background-color: $color-bg;
            .title-inner {
                display: flex;
                justify-content: space-between;
                max-width: 1460px;
                margin: 0 auto;
                padding: 0 40px;
            }
            .title {
                margin: 0;
                font-size: 20px;
                line-height: 30px;
                text-align: left;
                @include pc {
                    br {
                        display: none;
                    }
                }
            }
            @include mobile {
                margin: -12px 0 40px;
                padding: 24px 0;
                font-size: $font-large-2;
                line-height: 33px;
                .title-inner {
                    padding: 0 20px;
                }
                .title {
                    margin: 0;
                    font-size: 14px;
                    line-height: 23px;
                }
            }
            & + .inner {
                padding-top: 0;
            }
        }

        .btn-more {
            position: absolute;
            @include pc {
                top: 102px; //BTOCSITE-188
                right: 40px;
                .btn-link {
                    font-size: 14px;
                    line-height: 24px;
                    text-decoration: underline;
                }
            }
            @include mobile {
                top: 54px; //BTOCSITE-188
                right: 0;
                left: 0;
                text-align: center;
            }
        }
        /* 20210517 수정 */
        &.link-story { 
            padding-bottom:48px;
            .title {
                margin-bottom: 0;
            }
            @include mobile {
                padding-bottom:40px;
            }
            .inner {
                //padding-top:0;
            }
            /* //20210517 수정 */
        }
        .story-link {
            margin-top:24px;
            text-align:center;
            @include mobile {
                margin-top:20px;
            }
            ul {
                font-size:0;
                @include mobile {
                    margin-top:-12px;
                    width: 45%;
                    min-width: 270px;
                    margin: 0 auto;
                }
                li {
                    display:inline-block;
                    @include mobile {
                        margin-top:12px;
                    }
                    a {
                        position:relative;
                        font-size:16px;
                        line-height:26px;
                        @include mobile {
                            font-size:14px;
                            line-height:22px;
                        }
                        &:after {
                            content:' ';
                            display:inline-block;
                            margin:0 8px;
                            width:1px;
                            height:10px;
                            background-color:#ddd;
                        }
                    }
                    &:last-child {
                        a:after {
                            display:none;
                        }
                    }
                }
            }
        }
        &.tag-subscribe-story {
            margin-bottom:72px; //BTOCSITE-188 
            padding: 32px 0 28px;
            border-top: 0;
            background-color: $color-bg;
            .flexbox-wrap {
                max-width: 1460px;
                margin: 0 auto;
                padding: 0 40px;
                .flexbox {
                    width: 100%;
                }
            }
            .title-area {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                .title {
                    margin: 0;
                    font-size: 20px;
                    line-height: 30px;
                    em {
                        color: $color-primary;
                    }
                }
            }
            @include mobile {
                margin-bottom:44px; //BTOCSITE-188 
                padding: 24px 0 18px;
                .flexbox-wrap {
                    padding: 0;
                    .flexbox {
                        padding-bottom: 0;
                    }
                }
                .title-area {
                    padding: 0 20px;
                    .title {
                        font-size: 14px;
                        line-height: 23px;
                    }
                }
            }
            .tag-lists-wrap {
                position: relative;
                margin: 0 -6px;
                @include mobile {
                    margin: 0;
                    .scroll-controls {
                        .btn-arrow {
                            position: absolute;
                            top: 0;
                            width: 40px;
                            height: 100%;
                            padding: 0;
                            border-radius: 0;
                            &:before {
                                display: none;
                            }
                            &.prev {
                                left: 0;
                                background-image: linear-gradient(to left, rgba($color-bg, 0), $color-bg);
                            }
                            &.next {
                                right: 0;
                                background-image: linear-gradient(to right, rgba($color-bg, 0), $color-bg);
                            }
                        }
                    }
                }
            }
            .tag-lists {
                @include pc {
                    width: auto !important;
                }
                padding: 0;
                li {
                    display: inline-block;
                    margin: 0;
                    padding: 0 6px 12px;
                    &:last-child {
                    }
                }
                .tag {
                    padding: 14px 16px 14px 20px;
                    .text {
                        padding-right: 24px;
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
                @include mobile {
                    padding: 0 16px;
                    li {
                        display: inline-block;
                        margin: 0;
                        padding: 0 4px 12px;
                        &:last-child {
                        }
                    }
                    .tag {
                        padding: 11px 12px 11px 16px;
                        .text {
                            padding-right: 12px;
                            font-size: 16px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }
        /* 20210518 추가*/ 
        &.tag-subscribe-story2,
        &.tag-subscribe-story3 {
            // margin-bottom: -16px;
            margin-bottom:72px; //BTOCSITE-188 
            padding: 32px 0 28px;
            border-top: 0;
            background-color: $color-bg;
            .flexbox-wrap {
                max-width: 1460px;
                margin: 0 auto;
                padding: 0 40px;
                .flexbox {
                    width: 100%;
                }
            }
            .title-area {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                .title {
                    margin: 0;
                    font-size: 20px;
                    line-height: 30px;
                    em {
                        color: $color-primary;
                    }
                }
            }
            @include mobile {
                margin-bottom:44px; //BTOCSITE-188 
                padding: 24px 0 18px;
                .flexbox-wrap {
                    padding: 0;
                    .flexbox {
                        padding-bottom: 0;
                    }
                }
                .title-area {
                    padding: 0 20px;
                    .title {
                        font-size: 14px;
                        line-height: 23px;
                    }
                }
            }
            .tag-lists-wrap {
                position: relative;
                margin: 0 -6px;
                @include mobile {
                    margin: 0;
                    .scroll-controls {
                        .btn-arrow {
                            position: absolute;
                            top: 0;
                            width: 40px;
                            height: 100%;
                            padding: 0;
                            border-radius: 0;
                            &:before {
                                display: none;
                            }
                            &.prev {
                                left: 0;
                                background-image: linear-gradient(to left, rgba($color-bg, 0), $color-bg);
                            }
                            &.next {
                                right: 0;
                                background-image: linear-gradient(to right, rgba($color-bg, 0), $color-bg);
                            }
                        }
                    }
                }
            }
            .tag-lists {
                @include pc {
                    width: auto !important;
                }
                padding: 0;
                li {
                    display: inline-block;
                    margin: 0;
                    padding: 0 6px 12px;
                    &:last-child {
                    }
                }
                .tag {
                    padding: 14px 16px 14px 20px;
                    .text {
                        padding-right: 24px;
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
                @include mobile {
                    padding: 0 16px;
                    li {
                        display: inline-block;
                        margin: 0;
                        padding: 0 4px 12px;
                        &:last-child {
                        }
                    }
                    .tag {
                        padding: 11px 12px 11px 16px;
                        .text {
                            padding-right: 12px;
                            font-size: 16px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }
        /* //20210518 추가*/
    }

    .story-count {
        margin-bottom: 12px;
        font-weight: 700;
        @include font-medium;
        @include mobile {
            margin-bottom: 10px;
        }
    }
    .subscribe-wrap {
        width: 100%;
        &.fixed {
            background-color: $color-bg-wt;
            box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
        }

        .inner {
            max-width: 1460px;
            margin: 0 auto;
            padding: 0 40px;
            @include mobile {
                max-width: 100%;
                padding: 0 16px;
            }
            &:first-child {
                padding-top: 0;
                @include mobile {
                    padding-top: 0;
                }
            }
        }
    }
    .subscribe-tag {
        max-width: 1460px;
        margin: 0 auto;
        padding: 48px 40px 32px;
        text-align: center;
        @include mobile {
            max-width: 100%;
            padding: 32px 4px 24px;
        }
        .tag-box {
            position: relative;
            display: inline-block;
            padding: 8px 56px 8px 24px;
            border-radius: 100px;
            border: 2px solid $color-bg-wt;
            background-color: $color-bg-wt;
            box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
            .tag-name {
                margin-right: 28px;
                font-size: $font-large-1;
                line-height: 30px;
                vertical-align: middle;
                @include mobile {
                    margin-right: 24px;
                    font-size: $font-medium;
                    line-height: 23px;
                }
            }
            .btn {
                min-width: 58px;
                padding: 7px 11px;
                font-size: 12px;
                line-height: 1;
                vertical-align: middle;
            }
            .btn-close {
                position: absolute;
                top: 50%;
                right: 22px;
                width: 20px;
                height: 20px;
                margin-top: -10px;
                background: url('/lg5-common/images/icons/btn-delete-20.svg') no-repeat 0 0;
            }
            &.subscribed {
                border-color: $color-primary;
                .tag-name {
                    color: $color-primary;
                }
                .btn {
                    border-color: $color-primary;
                    background-color: $color-primary;
                    color: $color-white;
                }
            }
        }
    }

    .flexbox-wrap {
        position: relative;
        font-size: 0;
        //BTOCSITE-8513 스토리 리사이징시 UI 찌그러짐 현상 개선
        .flexbox:not(.tag-area) {
            display: inline-block;
            vertical-align: top;
             // BTOCSITE-9038
             height:541px;
            @include mobile{
                margin-top: 8px;
                padding:0;
                height:260px;
            }
        }
        @include mobile {
            .flexbox:not(.tag-area) {
                width: calc(100% / 2);
                &.tag-area {
                    padding-bottom: 40px;
                }
            }
        }
        .box {
            /* BTOCSITE-8513 스토리 리사이징시 UI 찌그러짐 현상 개선 */
            position: relative;
            border-radius: 8px;
            background-color: $color-bg-wt;
            box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.14);
            height: 100%;
            &:not(.review){
                @include mobile {
                    display: flex;
                    flex-direction:column;
                }
            }
            .flag-wrap + .card-title{margin-top: 3px;}
            .card-title + .tag-wrap{
                margin-top:28px;
                @include mobile{
                    margin-top: 6px;
                }
            }
            &.video {
                overflow: hidden;
                // //BTOCSITE-9038
                //flex:1;
                .visual-area {
                    position: relative;
                    //BTOCSITE-9038
                    height: 68.03%;
                    display: block;
                    flex: none;
                    @include mobile{
                        height:62.65%;
                        border-radius: 0;
                    overflow: hidden;
                    }
                    &:after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        border-radius: 8px 8px 0 0;
                        background-color: rgba(0,0,0,.2);
                        content: '';
                    }
                        //BTOCSITE-9038
                        img{
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-radius: 0;
                        top:0;
                            left:50%;
                        height: auto;
                        width: 100%;
                        transform: translate(-50%, 0%);
                        @include mobile{
                            border-radius: 0;
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .btn-video {
                    position: absolute;
                    //BTOCSITE-9038
                    top: 0;
                    left: 0%;
                    z-index: 1;
                    width: 100%;
                    height: 68.03%;
                    background: url('/lg5-common/images/icons/btn-play-32-black.svg') no-repeat center center;
                    background-size: 48px 48px;
                    @include mobile{
                        background-size: 41px 41px;
                        height: 62.65%;
                    }
                }
                // BTOCSITE-9038
                .flag-wrap .flag {
                    border-color:#000;
                    color: #000;
                    @include mobile {
                        border-color: #000;
                        color: #000;
                        font-size:11px;
                        line-height: 16px;
                        padding:2px 8px;
                        height: 20px;
                    }
                }
                .text-area {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    // BTOCSITE-9038
                    padding:20px 24px;
                    background: #fff;
                    color: #000;
                    height: 31.97%;
                    @include mobile {
                        padding:10px 12px;
                        height: 37.35%
                    }
                    // BTOCSITE-9038
                    .card-title {
                        @include textEllipsisClamp(2);
                        max-height: 69px;
                        font-size:24px;
                        line-height:32px;
                        // @include mobile{
                        //     font-size:24px;
                        //     line-height:32px;
                        // }
                        @media screen and (max-width: 480px){
                            font-size:14px;
                            line-height:20px;
                            margin-top: 2px;
                            max-height: 43px;
                        }
                        }
                    // BTOCSITE-9038
                    .tag-wrap {
                        position: absolute;
                        bottom:24px;
                        @include mobile{
                            bottom:12px;
                    }
                        .tags {
                        @include mobile{
                                .tag{
                                    display:none;
                                    text-decoration: none;
                                    a{
                                        text-decoration: underline;
                                        color:#000;
                                        line-height: 13px;
                                    }
                                    &:first-child{
                                        display:block;
                                    }
                                }
                            }
                        }
                    }
                }
                .date {
                    position: absolute;
                    top: 24px;
                    right: 32px;
                    font-weight: 400;
                    color: $color-gray-1;
                    @include mobile {
                        top: 21px;
                        right: 16px;
                    }
                }
            }
            /* //BTOCSITE-8513 스토리 리사이징시 UI 찌그러짐 현상 개선 */
            &.image,
            &.review {
                .visual-area {
                    @include mobile{
                        height: 100%;
                    }
                    &:after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        border-radius: 8px;
                        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 55%, #000 100%);
                        opacity: .5;
                        content: '';
                        @include mobile{
                            border-radius:0;
                        }
                    }
                }
            }
            /* BTOCSITE-8513 스토리 리사이징시 UI 찌그러짐 현상 개선 */
            &.image {
                overflow: hidden;
                .visual-area{
                    //BTOCSITE-9038
                    height: 68.03%;
                    position: relative;
                    display: block;
                    flex: none;
                    background: rgb(244, 244, 244);
                    @include mobile{
                        height:62.65%;
                        border-radius: 0;
                        overflow: hidden;
                    }
                    &:after {
                        display: none;
                    }
                }
                img{
                    position: absolute;
                    top: 0;
                    right: 0;
                    //BTOCSITE-9038
                    border-radius: 0;
                    top:0;
                    left:50%;
                    height: auto;
                    width: 100%;
                    transform: translate(-50%, 0%);
                    @include mobile{
                        border-radius: 0;
                        width: 100%;
                        height: auto;
                    }
                }
                .flag-wrap .flag {
                    // BTOCSITE-9038 
                    border-color:#000;
                    color: #000;
                    @include mobile {
                        border-color: #000;
                        color: #000;
                        font-size:11px;
                        line-height: 16px;
                        padding:2px 8px;
                        height: 20px;
                    }
                }
                .card-title + .tag-wrap{
                    margin-top:16px;
                    @include mobile{
                        margin-top:6px;
                    }
                }
                .text-area {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    z-index: 1;
                    // BTOCSITE-9038
                    color: #000;
                    height: 31.97%;
                    background: #fff;
                    padding-top:20px;
                    @include mobile{
                        padding:10px 12px;
                        height:37.35%;
                    }
                    .card-title {
                        @include textEllipsisClamp(2);
                        // BTOCSITE-9038
                        max-height: 69px;
                        font-size:24px;
                        line-height:32px;
                        // @include mobile{
                        //     font-size:24px;
                        //     line-height:32px;
                        // }
                        @media screen and (max-width: 480px){
                            font-size:14px;
                            line-height:20px;
                            margin-top: 2px;
                            max-height: 43px;
                        }
                    }
                    
                    .tag-wrap {
                        position: absolute;
                        // BTOCSITE-9038
                        bottom:24px;
                        @include mobile{
                            bottom:12px;
                        }
                        .tags {
                            @include mobile{
                                .tag{
                                    display:none;
                                    text-decoration: none;
                                    a{
                                        text-decoration: underline;
                                        color:#000;
                                        line-height: 13px;
                                    }
                                    &:first-child{
                                        display:block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            /* //BTOCSITE-8513 스토리 리사이징시 UI 찌그러짐 현상 개선 */
            &.review {
                box-shadow: none;

                .flag-wrap .flag {
                    border-color: rgba(255,255,255,.4);
                    color: $color-white;
                }
                .text-area {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    z-index: 1;
                    color: $color-white;
                    .btn-link {
                        display:none;
                        margin-top: 16px;
                        color: $color-white;
                        &:after {
                            background-image: url('/lg5-common/images/icons/btn-arr-20x20-white.svg');
                        }
                    }
                }
            }
        }
        /* BTOCSITE-8513 스토리 리사이징시 UI 찌그러짐 현상 개선 */
        .visual-area {
            display: block;
            flex:1;
            img {
                width: 100%;
            }
        }
        /* //BTOCSITE-8513 스토리 리사이징시 UI 찌그러짐 현상 개선 */
        .text-area {
            padding: 24px 24px 32px;
            @include mobile {
                padding: 20px 20px 32px;
                background-color: #fff;
            }
            .card-title {
                max-height: 60px;
                font-weight: 700;
                @include font-large-1;
                display: block;
                @include textEllipsisClamp(2);
                @include mobile {
                    max-height: 48px;
                    margin-top: 2px;
                }
            }
            .tag-wrap {
                position: absolute;
                bottom:40px;
                @include mobile{
                    bottom:12px;
                    // margin-right:2px;
                    overflow: hidden;;
                }
                .tags {
                    font-size: 0;
                }
                .tag {
                    display: inline-block;
                    font-weight: normal;
                    font-size:16px;
                    line-height: 24px;
                    text-decoration: underline;
                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                    
                    @include mobile{
                        font-size:12px;
                        line-height: 16px;
                        display:none;
                        &:first-child{
                            display:block;
                        }
                        a{
                            word-break: normal ;
                        }
                    }
                }
            }
        }
        .date {
            display: none;
            position: absolute;
            top: 16px;
            right: 16px;
            font-weight: 500;
            @include font($font-small,24px);
            color: $color-white;
            @include mobile {
                line-height: 22px;
            }
        }
        .sns-wrap {
            display:none; /* 0402 삭제요청 */
            position: absolute;
            top: 16px;
            right: 16px;
            z-index: 1;
            .sns {
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-left: 8px;
                background: no-repeat 0 0;
                &:first-child {
                    margin-left: 0;
                }
                &.instagram {
                    background-image: url('/lg5-common/images/icons/icon-instagram-24.svg');
                }
                &.blog {
                    background-image: url('/lg5-common/images/icons/icon-naver-blog-24.svg');
                }
                &.post {
                    background-image: url('/lg5-common/images/icons/icon-naver-post-24.svg');
                }
            }
        }
    }

    .btn-moreview {
        width: 177px;
        height: 46px;
        border-radius: 23px;
        font-size:16px;
        margin-top: 48px;
        &:before{
            height: 27px;
        }
        @include mobile{
            font-size:14px;
            height: 40px;
            width: 148.5px;
        }
    }
    .tag-area {
        .title {
            display: block;
            margin: 8px 0 24px 23px;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            text-align: left;
            @include mobile {
                margin: 3px 0 24px 12px;
                font-size:24px;
                line-height:33px;
            }
        }
        .tag-lists {
            padding:0 8px;
            > li {
                margin-top: 16px;
                @include mobile {
                    margin-top:14px;
                }
            }
        }
        .tag {
            display: table;
            padding: 16px 20px;
            border-radius: 100px;
            box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.14);
            background-color: $color-bg-wt;
            @include mobile {
                padding: 12px 16px;
            }
            .text,
            .btn {
                display: table-cell;
                vertical-align: middle;
            }
            .text {
                padding-right: 24px;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                word-break: break-all;
                padding-right:20px;
            }
            .btn {
                padding: 7px 12px;
                font-size: 12px;
                line-height: 1;
                color: $color-default;
                &:focus,
                &:hover,
                &:active {
                    background-color: $color-primary;
                    color: $color-white;
                }
            }
        }
    }

    .story-review {
        .flexbox-wrap {
            margin: 0 -12px;
            @include mobile {
                margin: 0;
            }
            .flexbox{
                //position: static !important;
                display: inline-block;
                height: 358px;
                margin-top: 0;
                width: calc(100% / 4);
                padding:0 12px;
                @include mobile{
                    height: 179px;
                }
                .box.review{
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    .visual-area{
                        .image{
                            img{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                -ms-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                                max-width: none;
                                width: auto;
                                background-position: center center;
                                min-width: 100%;
                                min-height: 100%;
                                height: 100%;
                                @include mobile{
                                    object-fit:cover;
                                }
                            }
                        }
                    }
                    .text-area{
                        @include mobile{
                            background:transparent !important;
                        }
                    }
                }
            }
            .text-area{
                padding:24px 24px 40px;
                @include mobile{
                    padding:16px;
                }
            }
            .card-title{
                font-size:26px;
                line-height: 34px;
                max-height: 74px;
                @include mobile{
                    font-size:15px;
                    line-height: 21px;
                }
            }
        }
        @media (min-width:$tablet-s-width + 1) and (max-width:1057px) {
            .flexbox-wrap {
                margin-bottom: -24px;
                .flexbox {
                    width: 50%;
                    padding-bottom: 24px;
                }
            }
        }
        @include mobile {
            margin: 0 -16px;
            padding: 39px 0 0;
            .slide-content {
                padding: 0 16px;
                .slide-conts {
                    padding: 0 8px 0px 0;
                    // width: 43vw;

                }
            }
            .indi-wrap {
                bottom: 0;
            }
            .slide-controls button {
                margin-top: -35px;
                &.prev {
                    right: 8px;
                }
                &.next {
                    right: 8px;
                }
            }
        }
    }

}

// [팝업] 라이프 스타일에 맞는 가전을 찾아볼까요?
.tag-subscribe-wrap {
    &.story-tag-wrap {
        .tag-lists .sect {
            margin-top: 48px;
            &:first-child {
                margin-top: 0;
            }
            .tag-group > ul {
                margin-top: -8px;
                .tags {
                    margin: 8px 0 0 8px;
                }
            }
        }
    }
}

// 상품 상세 스탠바이미 진입 링크
.stanbyme-qna{
    display:flex;
    justify-content:center;
    align-items:center;
    padding:27px 20px;
    border:1px solid #ddd;
    border-radius:8px;
    p{
        position:relative;
        padding-left:30px;
        font-size:$font-medium;
        font-weight:$font-weight-bold;
        line-height:23px;
        &:before{
            position: absolute;
            top:50%;
            left:0;
            width:24px;
            height:24px;
            background: url(/lg5-common/images/STS/img_review_24.svg) 0 0 no-repeat;
            background-size: 100% 100%;
            transform:translateY(-50%);
            content: '';
        }
        .mobile-only{
            display:none;
        }
    }
    .btn-link{
        margin-left:14px;
        line-height:1;
        &:after{
            top:calc(50% + 1px);
            transform:translateY(-50%);
        }
    }
    @include tablet {
        flex-direction:column;
        .btn-link{
            margin:12px 0 0 0;
        }
    }
    @include mobile {
        p{
            padding:48px 0 0 0;
            font-size:$font-small;
            line-height:22px;
            text-align:center;
            word-break:keep-all;
            &:before{
                top:0;
                left:50%;
                width:40px;
                height:40px;
                background: url(/lg5-common/images/STS/img_review_80.svg) 0 0 no-repeat;
                background-size: 100% 100%;
                transform:translateX(-50%) translateY(0);
            }
            .mobile-only{
                display:block;
            }
        }
    }
}

// 스탠바이미
.contents.stanbyme {
    @import "stanbyme";
}

//BTOCSITE-188
#popup-tagMnger {
    @include mobile {
        min-height:auto !important;
    }
}

.story-real-review { opacity:0;
    &.active { opacity: 1;}
}