@import "../../support/form-field";


.inner{
	max-width:1460px;
	margin:0 auto;
	padding:0 40px 100px; // BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
	@include mobile {
		padding:0 0 48px; // BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
	}
}

// list
.visual-wrap {
    position:relative;
	margin-bottom:40px;
	height:480px;
    @include mobile {
		margin-bottom:32px;
		height:326px;
    }
	.img-v{
		height:100%;
		background-size:cover;
		@include pc{
			background-position:78% center;
			&.pc{
				display:block;
			}
			&.m{
				display:none;
			}
		}
		@include mobile{
			background-position:center bottom;
			&.pc{
				display:none;
			}
			&.m{
				display:block;
			}
		}
	}
    .tit-wrap {
        position:absolute;
        top:calc(50% - 25px);
        left:10.10vw;
		right:0;
        transform:translateY(-50%);
		max-width:1920px;
		margin:0 auto;
        @include mobile {
            top:31px;
            left:20px;
            right:20px;
            transform:translateY(0);
        }
        .tit {
            font-size:52px;
            line-height:70px;
            font-weight:700;
            color: #000;
            @include mobile {
                font-size:25px;
                line-height:31px;
            }
        }
        .txt {
            display:block;
            padding-top:18px;
            font-size:24px;
            line-height:34px;
            font-weight:500;
            color: #000;
            @include mobile {
                padding-top:11px;
                font-size:14px;
                line-height:20px;
            }
        }
    }
	@include pc{
		.mo-only{
			display:none;
		}
	}
}
.stanbyme-list{
	.tit-sbm{
		@include pc{
			display:flex;
			margin:70px 0 48px;
			align-items:center;
		}
		@include mobile{
			margin:50px 0 40px;
		}
		.tit{
			font-weight:$font-weight-bold;
			@include pc{
				margin-right:17px;
				font-size:$font-large-4;
			}
			@include mobile{
				font-size:$font-large-2;
			}
		}
		.sbm-qna{
			display:flex;
			flex:auto;			
			align-items:center;
			@include mobile{
				margin-top:5px;
			}
			p{
				flex:auto;
				font-size:$font-medium;
				@include pc{
					padding:15px 0;
					.mo-only{
						display:none;
						@include tablet{
							display:block;
						}
					}
				}
				@include mobile{
					font-size:$font-small;
					line-height:20px;
				}
				+.btn{
					span{
						position:relative;
						padding-left:30px;
						&:before{
							position: absolute;
							top:0;
							left:0;
							width:24px;
							height:24px;
							background: url(/lg5-common/images/STS/ic_write_24.svg) 0 0 no-repeat;
							background-size: 100% 100%;
							content: '';
						}
						@include mobile{
							padding-left:24px;
							font-size:13px;
							&:before{
								width:20px;
								height:20px;
							}
						}
					}
					@include mobile{
						padding:6px 19px 6px 16px;
						min-width:auto;
						height:43px;
					}
				}
			}
		}
	}
	@include mobile{
		.tab-content{
			padding:0 16px;
		}
	}
	// sorting
	.sorting-wrap {
		padding-bottom:12px;
		@include clearfix;
		.total {
			float:left;
			font-weight:700;
			font-size:$font-small;
			.count {
				color: $color-primary;
			}
		}
		.sort-box {
			float:right;
			.sort-select-wrap {
				float:left;
				margin-left:40px;
				&:first-child {
					margin-left:0;
				}
			}
			.unit {
				font-weight:$font-small;
			}
		}
		@include mobile {
			.total {
				font-size:13px;
			}
			.sort-box {
				.sort-select-wrap {
					margin-left:20px;
				}
			}
			&.row-type {
				.total {
					float:none;
				}
				.sort-box {
					float:none;
					margin-top:16px;
					@include clearfix;
				}
			}
		}
	}
	// table
	.tb_row{
		&.board-type{
			tbody{
				tr{
					position:relative;
					@include mobile{
						padding:16px 12px;
					}
				}
				td{
					.txt-cnt{
						display:none;
						@include mobile{
							display:inline-block;
							margin-right:3px;
						}
					}
					.no-data{
						padding:39px 0 56px;
						@include mobile{
							padding:44px 0;
						}
					}
				}
				.board-tit{
					>a{
						align-items:center;
						p{
							line-height:26px;
						}
						@include mobile{
							margin-right:44px;
							.flag-wrap{
								.flag{
									font-size:11px;
									line-height:19px;
									&:first-child{
										margin-left:0;
									}
								}
							}
						}
					}
					.count{
						display:inline-block;
						margin-left:12px;
						height:24px;
						border:1px solid #ddd;
						border-radius:12px;
						line-height:22px;
						font-size:$font-x-small;
						font-weight:$font-weight-bold;
						color:$color-primary;
						@include pc{
							padding:0 12px;
						}
						@include mobile{
							position:absolute;
							top:50%;
							right:0;
							width:40px;
							height:40px;
							border-radius:100%;
							transform:translateY(-50%);
							line-height:38px;
							text-align:center;
							font-size:13px;
							font-weight:$font-weight-medium;
						}
					}
				}
			}
		}
	}
	// review
	//BTOCSITE-6436 스탠바이비 클럽 내 이벤트 배너 삽입 요청
	.component-inner{
		margin-top:78px; // BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
		@include mobile{
			margin-top:30px; // BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
		}
	}

	// 리스트에 데이터 없는 케이스
	.result-wrap.no-data {
		.bullet-list {
			&.top-border {
				margin-top:0;
				padding-top:24px;
				border:0;
			}
		}
	}
}

// register, modify
.stanbyme-write{
	margin-top:70px;
	.tit-wrap{
		margin-bottom:48px;
		.tit{
			padding-bottom: 16px;
			color: #000;
			font-size: 28px;
			font-weight: 700;
			line-height: 1.63;
			border-bottom: 2px solid #222;
		}
	}
	.file-item {
		.file-image {
			.file-preview {
				position: relative;
				> img {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					height: auto;
					transform: translate(-50%, -50%);
				}
			}
		}
		&.modify {
			.file-btns {
				.btn-del,.btn-upload { display:none;}
				.btn-file-del { display:block; }
			}
			.file-preview {
				background: none;
			}
		}
	}
	.form-wrap {
		.input-wrap {
			textarea:focus + .inner-text {
				border-color: $color-primary ;
			}
		}
	}

	@include mobile{
		margin-top:32px;
		padding:0 16px;
		.image-file-wrap{
			margin:0 -16px;
			overflow-x:auto;
			white-space:nowrap;
			-ms-overflow-style:none;
			scrollbar-width:none;
			&::-webkit-scrollbar{
				display:none;
			}
			.file-scroll-wrap{
				display:flex;
				padding:0 16px;
				width:calc(100% + 36px);
			}
			.file-item{
				width:33%;
				max-width:86px;
				+.file-item{
					margin-left:12px;
				}
				.file-image{
					.file-preview{
						width:100%;
						height:0;
						padding-bottom:100%;
					}
					.file-btns{
						.btn-upload{
							width:20px;
							height:20px;
							background-size:100% 100%;
						}
					}
				}
			}
		}
	}
}


// detail
.stanbyme-detail{
	padding: 57px 16px 0;
	position: relative;
	@include mobile {
		padding-top: 30px;
	}
	.btn-box{
		.btn+.btn{
			margin-left: 4px;
		}
		@include pc {
			display: inline-block;
			position:absolute;
			right:0;
			bottom:0;
			padding-bottom: 18px;
			&.mobile-only{
				display:none;
			}
		}
		@include mobile {
			border-top:1px solid $color-border;
			padding: 16px 0 60px 0;
			text-align: center;
			&.pc-only{
				display:none;
			}
		}
	}
	&-head{
		border-bottom: 1px solid $color-gray-1;
		padding-bottom: 18px;
		position: relative;
		@include mobile {
			padding-bottom: 16px;
		}
		.head {
			&-title {
				font-weight:$font-weight-bold;
				font-size:32px;
				line-height:52px;

				@include mobile {
					font-size:24px;
					line-height: 30px;
					padding-top: 5px;
				}
			}
			&-infos {
				margin-top:8px;
				display: inline-block;
				@include clearfix;
				> li {
					float:left;
					color:#000;
					@include font-small;
					line-height: 20px;
					font-weight:normal;
					position:relative;
					margin-left:8px;
					padding-left:9px;
					&:before {
						content:'';
						position:absolute;
						left:0;
						top:50%;
						width:1px;
						height:10px;
						margin-top: -4px;
						background:#ddd;
					}
				}
				&:first-of-type {
					> li {
						&:first-child {
							padding-left: 0;
							margin-left: 0;
							&::before {
								display: none;
							}
						}
					}
				}
				@include mobile{
					margin-top: 10px;
				}
			}
		}
	}
	&-body{
		padding: 60px 0 80px;
		@include mobile{
			padding: 40px 0 40px;
		}
		.detail-content{
			white-space: pre-line;
			p {
				color:$color-default;
				font-size: $font-medium;
				line-height: 26px;
				@include mobile{
					font-size: 14px;
					line-height: 22px;
				}
			}
			strong {
				color:inherit;
				font-size:inherit;
				line-height:inherit;
			}
			.img-wrap{
				margin-top: 40px;
				@include mobile{
					margin-top:24px;
				}
				img {
					max-width:100%;
				}
			}
		}
	}
	& .comment-wrap {
		margin-top: 120px;
        @include mobile {
			margin-top: 60px;
		}
	}
	&-navi{
		// margin-top: 120px;
		.navi {
            display:flex;
            padding:24px;
            font-size:0;
            background-color: #fff;
            border-top: 1px solid #ddd;
            @include clearfix;
            &-label, &-desc, &-date, &-views {
                display:inline-block;
                @include font-medium;
                vertical-align:middle;
            }
            &-label {
                position: relative;
                margin-right:64px;
                color: #666;
            }
            &-desc {
                color:#000;
                font-weight:normal;
                flex: 1;
                @include textEllipsis();
            }
            &-date,
            &-views {
                color: #767676;
                margin-left: 64px;
            }
            &:last-child {
                border-bottom: 1px solid #ddd;
            }
        }
        @include mobile {
            padding: 0;
			// margin-top: 60px;
            .navi {
                padding:16px 12px;
                flex-wrap: wrap;
                &-label {
                    margin-right:24px;
                }
                &-desc {
                    flex: auto;
                    width: calc(100% - 70px);
                }
                &-date {
                    margin:4px 0 0 64px;
                }
                &-views {
                    position: relative;
                    margin:4px 0 0 8px;
                    padding-left:9px;
                    &::before {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 10px;
                        position: absolute;
                        background-color: #ddd;
                        top: 6px;
                        left: 0;
                    }
                }
            }
        }
	}
	.btn-group {
		margin: 64px auto 0;
		@include mobile{
			margin: 40px auto 0;
			padding: 0;
		}
	}
	.pagination{
		margin-bottom: 48px;
		@include mobile{
			margin-bottom: 50px;
		}
	}
}
.comment{
	&-head{
		border-bottom: 1px solid $color-default;
		padding-bottom: 11px;
		@include mobile{
			padding-bottom: 12px;
		}
		.total{
			position: relative;
			padding-left: 32px;
			color: $color-default;
			font-size: $font-medium;
			line-height: 26px;
			&:before{
				position: absolute;
				top:0;
				left:0;
				width:24px;
				height:24px;
				background: url(/lg5-common/images/STS/icon-reply_24.svg) 0 0 no-repeat;
				background-size: 100% 100%;
				content: '';
			}
			@include mobile{
				padding-left: 26px;
				font-size: 14px;
				line-height:20px;
				&:before{
					width:20px;
					height:20px;
					background-image:url(/lg5-common/images/STS/icon_reply_40.svg);
				}
				em {
					font-weight: 700;
				}
			}
		}
	}
	&-list{
		ul{
			>li{
				&+li{
					border-top:1px solid $color-border;
				}
				.no-comment{
					padding: 80px 0;
					p{
						color:$color-gray-1;
						font-size: $font-medium ;
						line-height: 26px;
						text-align: center;
					}
					@include mobile{
						padding: 60px 0;
						p{
							font-size: 14px;
							line-height: 20px;
						}
					}
				}
				&:last-child {
					border-bottom:1px solid $color-border;
				}
				&:first-child{
					border-bottom:none;
				}

			}
		}
	}
	&-content{
		position: relative;
		padding: 24px;
		@include mobile{
			padding: 16px 12px;
		}
		.comment-btn-box{
			position: absolute;
			right:24px;
			top:24px;
			text-align: right;
			.btn-text{
				font-weight: 400;
			}
			.btn-text+.btn-text{
				margin-left: 8px;
				position: relative;
				&:before{
					content: '';
					position: absolute;
					left: -6px;
					top: 50%;
					width: 1px;
					height: 10px;
					margin-top: -5px;
					background: $color-border;
				}
			}
			@include mobile{
				top: auto;
				bottom: 16px;
				right: 12px;
				.btn-text{
					text-decoration: none;
				}
			}
		}
		.info-name{
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;
			color: $color-default ;
			padding-bottom: 4px;
			@include mobile{
				font-size: 14px;
				line-height: 20px;
			}
		}
		.comment-text-wrap{
			p {
				font-size: 16px;
				line-height: 24px;
				@include mobile{
					font-size: 14px;
					line-height: 20px;
				}
				&.admin-delete{
					color: $color-gray-4;
				}
			}
		}
		.info-date{
			font-size: 14px;
			line-height: 20px;
			color: $color-gray-1;
			padding-top: 16px;
			.time{
				margin-left: 8px;
			}
			@include mobile{
				font-size: 13px;
				line-height: 19px;
				padding-top: 12px;
			}
		}
		&.admin-comment{
			background: $color-bg;
			.info-name{
				padding-left: 57px;
				position: relative;
				&:before{
					position: absolute;
					top:0;
					left:0;
					content: '관리자';
					display: inline-block;
					width: 49px;
					height: 24px;
					padding: 3px 0 4px 0;
					border-radius: 4px;
					border: solid 1px $color-primary;
					font-size: 12px;
					color: $color-primary;
					line-height: 17px;
					text-align: center;
					@include mobile{
						top:-3px;
					}
				}
			}
		}
		@include mobile{
			.comment-write{
				margin: 0 -12px;
				.form-wrap .forms .conts{
					margin-top: 0;
				}
			}
		}
	}
	&-write{
		height: 150px;
		@include mobile{
			height: 180px;
		}
		.form-wrap .forms .conts {
			width: 100%;
		}
		.text-form {
			textarea{
				height : 97px;
				padding: 16px 24px 5px 16px;
				line-height: 26px;
				@include mobile{
					height : 128px;
					line-height: 20px;
					padding: 12px 16px 5px 12px;
				}
				+ .inner-text{
					width:auto;
				}
				&:focus + .txt-count-box {
					border-color: $color-primary ;
				}
				&.valid{
					&+.txt-count-box{
						.comment-write-btn-box {
							.btn-cancel{
								display: inline-block !important;
							}
							.btn-confirm{
								border-color: $color-primary !important;
								background-color: $color-primary !important;
								color: #fff !important;
							}
						}
					} 
				}
				&::placeholder {
					white-space: pre-line;
					color: #666 !important;
					font-size:14px !important;
					@include mobile{
						font-size:13px !important;
						line-height: 20px;
					}
				}
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					white-space: pre-line;
					color: #666 !important;
					font-size:14px !important;
					@include mobile{
						font-size:13px !important;
						line-height: 20px;
					}
				}

				&:-ms-input-placeholder {
					white-space: pre-line;
					color: #666 !important;
					font-size:14px !important;
					@include mobile{
						font-size:13px !important;
						line-height: 20px;
					}
				}
			}
			.input-wrap{
				.txt-count-box{
					padding: 5px 24px 16px 16px;
					border: 1px solid $color-border;
					border-top: none;
					border-radius: 0 0 8px 8px;
					vertical-align: top;
					text-align: right;
					background: #fff;
					@include mobile{
						padding: 5px 16px 12px 12px;
					}
					.inner-text{
						display: inline-block;
						width: auto;
						padding: 0 12px 0 0;
						vertical-align:bottom;
						font-size: 14px;
						line-height:32px;
						text-align: right;
						border:none;
						@include mobile{
							font-size:13px;
						}
					}
					.comment-write-btn-box{
						display: inline-block;
						vertical-align: top;
						.btn {
							height:auto;
							display: inline-block;
							min-width:112px;
							padding:11px 31px;
							@include font-medium;
							text-align: center;
							vertical-align: top;
							border: 1px solid #fa263e;
							border-radius: 26px;
							background-color:#fa263e;
							background-image: linear-gradient(280deg, #fb5a72, #fa263e);
							color:#fff;
							font-weight:500;
							@include mobile {
								min-width:124px;
								font-size:16px;
								line-height:24px;
								padding:15px 25px;
								border-radius:28px;
							}
							&:hover,
							&:focus,
							&:active {
								background-color:#fa263e;
								background-image: none;
								color: $color-white;
							}
							&.gray {
								border-color: #ededed;
								background: #ededed;
								color: $color-gray-1;
								&:hover {
									background: #ededed;
									color: $color-gray-1;
								}
							}
							&.dark-gray {
								border-color: #767676;
								background: #767676;
								color: $color-white;
								&:hover {
									background: #767676;
									color: $color-white;
								}
							}
							&:disabled,
							&.disabled {
								border-color:#ddd !important;
								background:#f4f4f4;
								color:$color-gray-4;
								cursor:default;
								&:hover,
								&:focus,
								&:active {
									border-color:#ddd !important;
									background:#f4f4f4;
									color: $color-gray-4;
								}
							}
							// 작은사이즈 버튼
							&.size {
								min-width:64px;
								padding:3px 11px;
								border-radius:16px;
								@include font-small;
								@include mobile {
									padding:5px 15px;
									line-height:22px;
								}
								&.mix {
									padding:4px 12px;
								}
							}
							&.btn-cancel{
								display: none;
								margin-right: 8px;
							}
						}
					}
				}
			}
		}
	}

}

//BTOCSITE-6436 스탠바이비 클럽 내 이벤트 배너 삽입 요청
.stm-event-banner{
	.img-banner{
		display:block;
		width:100%;
		img{
			width:100%;
		}
		@include pc{
			.mo-only{
				display:none;
			}
		}
		@include mobile{
			.pc-only{
				display:none;
			}
		}
	}
}

.flag-wrap {
	.notice{
		display: inline-block;
		width: 60px;
		border: 1px solid #000;
		border-radius: 4px;
		font-size: 12px;
		font-weight: bold;
		line-height: 20px;
		text-align: center;
		vertical-align: middle;
		color:#000;
	}
	@include mobile{
		.notice {
			width: 64px;
		}
	}
}
// S : 자주 묻는 질문 : BTOCSITE-13933
.accordion-wrap {
	border-top:2px solid #222;
	&.faq-type > ul > .lists {
		margin-top:0;
		border-bottom:1px solid #ddd;;
		border-radius:0;
		&:first-child {
			border-top:1px solid #ddd;
		}
		&.on {
			background-color:#f4f4f4;
			box-shadow:none;
			.que {
				color:$color-primary;
			}
			.accord-btn:after {
				transform:rotate(270deg) !important;
			}
		}
	}
	&.faq-type > ul > .lists .head .accord-btn {
		padding:25px 136px 25px 72px;
		@include mobile {
			padding:16px 44px 16px 36px;
		}
		&:after {
			right:48px;
			width:24px;
			height:24px;
			margin-top:-12px;
			background:url('/lg5-common/images/icons/btn-arr-24x24-black.svg') no-repeat 0 0;
			border:none;
			transform:rotate(90deg);
			@include mobile {
				top:16px;
				right:12px;
				width:16px;
				height:16px;
				margin-top:0;
				background-size:16px 16px;
			}
		}
		.que {
			position:absolute; top:50%; left:24px;
			font-size:18px;
			font-weight:bold;
			transform:translateY(-50%);
			@include mobile {
				top:21px;
				left:12px;
				font-size:15px;
				transform:translateY(0);
			}
		}
		.title {
			margin-bottom:0;
			font-weight:500;
			@include mobile {
				padding:0;
			}
		}
	}
	&.faq-type > ul > .lists .accord-cont {
		padding:0 72px 48px;
		@include mobile {
			padding:0 44px 32px 36px;
			p {
				word-break: break-word;
			}
		}
	}
}
// E : 자주 묻는 질문 : BTOCSITE-13933